/* eslint-disable no-unused-vars */
import React from 'react';
import {Menu} from '@headlessui/react';
import {getAuthURL} from '../utils/SpotifyAuth';
import '../CSS/Component.css';
import {useSelector, useDispatch} from 'react-redux';
import {logOut} from '../slices/UserSlice';

function SpotifyLoginButton() {
  const dispatch = useDispatch();
  const {isLoggedIn, userName} = useSelector((state) => state.user);

  const handleLogout = () => {
    dispatch(logOut());
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  };

  const handleLogin = async () => {
    if (!isLoggedIn) {
      const authUrl = await getAuthURL();
      window.location.href = authUrl;
    }
  };

  return (
    <Menu as="div" className="dropdown-container">
      <Menu.Button as="button" onClick={handleLogin} className="login-button">
        {isLoggedIn ? userName : 'Sign in'}
      </Menu.Button>

      {isLoggedIn && (
        <Menu.Items as="div" className="dropdown-menu">
          <div className="dropdown-content">
            <Menu.Item>
              {({active}) => (
                <a href="#" className={`dropdown-item ${active ? 'active-class' : ''}`}>
                  Profile
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({active}) => (
                <a href="#" className={`dropdown-item ${active ? 'active-class' : ''}`}
                  onClick={handleLogout}>
                  Logout
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      )}
    </Menu>
  );
};

export default SpotifyLoginButton;
