const generateRandomString = (length) => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const values = crypto.getRandomValues(new Uint8Array(length));
  return values.reduce((acc, x) => acc + possible[x % possible.length], '');
};

const sha256 = async (plain) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
};

const base64encode = (input) => {
  return btoa(String.fromCharCode(...new Uint8Array(input)))
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
};


export const getAuthURL = async () => {
  // Generate a random String
  const codeVerifier = generateRandomString(64);
  // Use sha256 to encrypt the random string
  const hashed = await sha256(codeVerifier);
  // Use base64 to encrypt the sha256 string
  const codeChallenge = base64encode(hashed);
  // Store the random string in local
  localStorage.setItem('code_verifier', codeVerifier);

  const clientId = 'f4208dbda0644bf8bb0b1048ade777bd';
  const redirectUri = 'https://dachuanlispotifyreact.com';
  const scope = 'user-read-private user-read-email streaming user-read-playback-state user-top-read playlist-modify-private playlist-modify-public';

  const url = new URL('https://accounts.spotify.com/authorize');

  const params = {
    response_type: 'code',
    client_id: clientId,
    scope,
    code_challenge_method: 'S256',
    code_challenge: codeChallenge,
    redirect_uri: redirectUri,
  };

  url.search = new URLSearchParams(params).toString();
  return url.toString();
};

export const getToken = async (code) => {
  const codeVerifier = localStorage.getItem('code_verifier');
  const clientId = 'f4208dbda0644bf8bb0b1048ade777bd';
  const redirectUri = 'https://dachuanlispotifyreact.com';

  const formData = new URLSearchParams();
  formData.append('client_id', clientId);
  formData.append('grant_type', 'authorization_code');
  formData.append('code', code);
  formData.append('redirect_uri', redirectUri);
  formData.append('code_verifier', codeVerifier);

  try {
    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const userLoginData = await response.json();
    localStorage.setItem('access_token', userLoginData.access_token);
    localStorage.setItem('refresh_token', userLoginData.refresh_token);
    return userLoginData;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};
