import React, {useEffect, useState} from 'react';
import fetchSpotifyPlaylists from '../utils/SpotifyPlayList';
import {addTracktoPlaylist} from '../utils/SpotifyPlayList';

const AddTrack = ({onClose, trackUri}) => {
  const [playlists, setPlaylists] = useState([]);
  useEffect(() => {
    const fetchPlaylists = async () => {
      const userPlaylists = await fetchSpotifyPlaylists();
      setPlaylists(userPlaylists);
      console.log(userPlaylists);
    };

    document.addEventListener('mousedown', handleClickOutside);
    fetchPlaylists();
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleClickOutside = (event) => {
    const modalContent = document.getElementById('modal-content');
    if (modalContent && !modalContent.contains(event.target)) {
      onClose();
    }
  };

  const handleSubmit = (playlistId) => async (event) => {
    event.stopPropagation();

    try {
      await addTracktoPlaylist(playlistId, trackUri, 0);
      console.log(`Track added to playlist ${playlistId}`);
      onClose();
    } catch (error) {
      console.error('Failed to add track to playlist', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div id="modal-content" className="bg-white rounded-lg shadow-2xl p-6 max-w-2xl m-4 overflow-y-auto">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Add Song to Playlists</h2>
        <div className="space-y-4">
          {playlists.map((playlist) => (
            <div onClick={handleSubmit(playlist.id)}
              key={playlist.href}
              className="flex items-center p-4 bg-gray-800 text-white rounded-lg shadow transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-xl cursor-pointer"
            >
              <div className="flex-shrink-0 mr-3">
                <img src={playlist.images[0].url} alt="" className="w-12 h-12 rounded-full" />
              </div>
              <div>
                <div className="text-lg font-medium">{playlist.name}</div>
                <div className="text-gray-400 text-sm">{playlist.tracks.total} tracks</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddTrack;
