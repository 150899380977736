import axios from 'axios';

const getRefreshToken = async () => {
  const clientId = 'f4208dbda0644bf8bb0b1048ade777bd';
  const refreshToken = localStorage.getItem('refresh_token');
  const url = 'https://accounts.spotify.com/api/token';

  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refreshToken);
  params.append('client_id', clientId);

  try {
    const response = await axios.post(url, params.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const data = response.data;

    if (data.access_token) {
      localStorage.setItem('access_token', data.access_token);
      if (data.refresh_token) {
        localStorage.setItem('refresh_token', data.refresh_token);
      }
      return true;
    } else {
      console.error('No access token returned on refresh:', data);
      return false;
    }
  } catch (error) {
    console.error('Error occurred while refreshing token:', error);
    return false;
  }
};

export default getRefreshToken;
