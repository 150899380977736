import SpotifyLoginButton from '../component/SpotifyLoginButton';

export default function Example() {
  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <img
              className="h-11"
              src="https://react-app-spotify.s3.amazonaws.com/spotify_icon.png"
              alt="Spotify icon"
            />
            <h1 className="mt-24 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">
              Spotify Explorer: Your Personal Music Journey
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Dive into the heart of music with Spotify Explorer, your ultimate platform for
              discovering new hits and timeless classics. Designed for music enthusiasts and
              casual listeners alike, our React-based project offers intelligent recommendations
              of songs and albums tailored just for you. Manage your playlists effortlessly, search
              for your favorite tunes, and immerse yourself in the melodies with seamless playback.
              Whether you re looking to explore new genres or keep up with your current favorites,
              Spotify Explorer is your gateway to the vast universe of music at your fingertips.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <SpotifyLoginButton/>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            src="https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
