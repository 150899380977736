import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import PlayListsPage from './pages/PlayListsPage';
import ErrorPage from './pages/ErrorPage';
import TrackDetail from './component/TrackDetail';
import './CSS/App.css';
import SpotifyLoginButton from './component/SpotifyLoginButton';
import SpotifyPlayer from './component/SpotifyPlayer';
import {useSelector} from 'react-redux';
import LoginPage from './pages/LoginPage';
import AuthHandler from './utils/AuthHandler';
import AlbumDetail from './component/AlbumDetail';
import PlayListsTrack from './component/PlayListsTrack';

function App() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  useEffect(() => {
    document.title = 'Spotify React App';
  }, []);

  return (
    <Router>
      <div>
        <nav className="header">
          <ul className="nav-list">
            <div className='nav-logo-container'>
              <img className="nav-logo" src='https://react-app-spotify.s3.amazonaws.com/spotify_icon.png' alt='Spotify' />
            </div>
            <li>
              <Link to="/" className="nav-link">Home</Link>
            </li>
            <li>
              <Link to="/search" className="nav-link">Search</Link>
            </li>
            <li>
              <Link to="/playlists" className="nav-link">PlayLists</Link>
            </li>
            <li>
              <SpotifyLoginButton/>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/search" element={<SearchPage/>} />
          <Route path="/playlists" element={<PlayListsPage/>} />
          <Route path="/track/:trackId" element={<TrackDetail/>} />
          <Route path="/album/:albumId" element={<AlbumDetail/>} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/login" element={<LoginPage/>} />
          <Route path="/playliststrack/:playlistsId" element={<PlayListsTrack/>}/>
        </Routes>
        {isLoggedIn && <SpotifyPlayer />}
        <AuthHandler />
      </div>
    </Router>
  );
}

export default App;
