import axiosInstance from '../interceptor/interceptor';

export const getTrackInfo = async (trackId) => {
  try {
    const response = await axiosInstance.get(`/tracks/${trackId}`);
    return response.data;
  } catch (error) {
    console.error('fetch track fail:', error);
    return null;
  }
};

export async function getTracksByArtist(artistId) {
  try {
    const response = await axiosInstance.get(`/artists/${artistId}/top-tracks`);
    return response.data.tracks;
  } catch (error) {
    console.error('Error fetching artist tracks:', error);
    return [];
  }
}

export const getAlbumInfo = async (albumId) => {
  try {
    const response = await axiosInstance.get(`/albums/${albumId}`);
    return response.data;
  } catch (error) {
    console.error('fetch track fail:', error);
    return null;
  }
};
