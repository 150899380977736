/* eslint-disable new-cap */
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {Recommendations} from '../utils/SpotifyGetRecommendations';
import {showRec} from './ToastSlice';

export const fetchRecommendations = createAsyncThunk(
    'player/fetchRecommendations',
    async (_, thunkAPI) => {
      const recommendations = await Recommendations();
      thunkAPI.dispatch(showRec());
      return recommendations;
    },
);


const initialState = {
  playingTrack: {trackUri: ''},
  RecommendationsTrack: [],
  RecommendationsAlbum: [],

};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setPlayingTrack: (state, action) => {
      state.playingTrack = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecommendations.fulfilled, (state, action) => {
      state.RecommendationsTrack = action.payload.tracks;
      state.RecommendationsAlbum = action.payload.albums;
    });
  },
});


export const {setPlayingTrack} = playerSlice.actions;

export default playerSlice.reducer;
