import React from 'react';
import '../CSS/SongsCard.css';
import {Link} from 'react-router-dom';

function ALbumCard({albumID, name, artist, img}) {
  return (
    <Link to={`/album/${albumID}`}>
      <div className="card-container">
        <img className="card-image" src={img} alt="Album cover" />
        <div className="card-content">
          <div className="font-bold">{name}</div>
          <p className="text-base">{artist}</p>
        </div>
      </div>
    </Link>
  );
};


export default ALbumCard;
