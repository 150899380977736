import {useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {logIn, logOut} from '../slices/UserSlice';
import fetchSpotifyUserInfo from './SpotifyUserInfo';
import {getToken} from './SpotifyAuth';
import {setUserID} from '../slices/UserSlice';

function AuthHandler() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  useLayoutEffect(() => {
    const checkUserStatus = async () => {
      const data = await fetchSpotifyUserInfo();
      if (data && data.display_name) {
        dispatch(logIn(data.display_name));
        dispatch(setUserID(data.id));
      } else {
        dispatch(logOut());
        navigate('/login');
      }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code && !isLoggedIn) {
      getToken(code).then(() => {
        window.history.replaceState({}, document.title, window.location.pathname);
        checkUserStatus();
      }).catch((error) => {
        console.error('Access token failed:', error);
      });
    } else {
      checkUserStatus();
    }
  }, [dispatch, navigate, isLoggedIn]);

  return null;
}

export default AuthHandler;
