import React from 'react';
import Playlists from '../component/PlayLists';

function PlayListsPage() {
  const accessToken = localStorage.getItem('access_token');

  return (
    <div className="text-center p-8">
      <h2 className="text-2xl font-bold">PlayLists</h2>
      <Playlists token={accessToken} />
    </div>
  );
}

export default PlayListsPage;
