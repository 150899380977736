import React, {useState, useEffect} from 'react';
import {Container, Form} from 'react-bootstrap';
import SpotifyWebApi from 'spotify-web-api-node';
import TrackSearchResult from './TrackSearchResult';

const clientId = 'f4208dbda0644bf8bb0b1048ade777bd';
const clientSecret = process.env.SPOTIFY_CLIENT_SECRET;

const spotifyApi = new SpotifyWebApi({
  clientId: clientId,
  clientSecret: clientSecret,
});

export default function Dashboard() {
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const accessToken = localStorage.getItem('access_token');

  // Update access token
  useEffect(() => {
    if (!accessToken) return;
    spotifyApi.setAccessToken(accessToken);
  }, [accessToken]);

  // Search logic
  useEffect(() => {
    if (!search) return setSearchResults([]);
    if (!accessToken) return;

    let cancel = false;
    spotifyApi.searchTracks(search).then((res) => {
      if (cancel) return;
      const tracks = res.body.tracks.items;
      const results = tracks.map((track) => formatTrack(track));
      setSearchResults(results);
    });

    return () => {
      cancel = true;
    };
  }, [search, accessToken]);

  // Format track
  const formatTrack = (track) => {
    const smallestAlbumImage = track.album.images.reduce((smallest, image) => {
      return image.height > smallest.height ? image : smallest;
    }, track.album.images[0]);

    return {
      artist: track.artists[0].name,
      title: track.name,
      uri: track.uri,
      albumUrl: smallestAlbumImage.url,
      id: track.id,
    };
  };

  return (
    <Container className="d-flex flex-column py-2" style={{height: '100vh'}}>
      <Form.Control
        type="search"
        placeholder="Search Songs/Artists"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="flex-grow-1 my-2" style={{overflowY: 'auto', paddingBottom: '80px'}}>
        {searchResults.map((track) => (
          <TrackSearchResult
            track={track}
            key={track.uri}
          />
        ))}
      </div>
    </Container>
  );
}
