import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  recToast: false,
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showRec: (state, action) => {
      state.recToast = true;
    },
    hideRec: (state, action) => {
      state.recToast = false;
    },
  },
});

export const {showRec, hideRec} = toastSlice.actions;
export default toastSlice.reducer;
