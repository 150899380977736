import {createSlice} from '@reduxjs/toolkit';

const initialState= {
  isLoggedIn: false,
  userName: '',
  userID: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logIn: (state, action) => {
      state.isLoggedIn = true;
      state.userName = action.payload;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.userName = '';
    },
    setUserID: (state, action) => {
      state.userID = action.payload;
    },
  },
});

export const {logIn, logOut, setUserID} = userSlice.actions;
export default userSlice.reducer;
