import React from 'react';
import {Link} from 'react-router-dom';

function TrackCard({trackID, name, artist, img}) {
  return (
    <Link to={`/track/${trackID}`} className="no-underline">
      <div className="bg-gray-800 hover:bg-gray-700 px-4 py-2 border-b border-gray-700 w-full">
        <div className="flex items-center space-x-4">
          <img className="w-24 h-24 object-cover" src={img} alt="Album cover" />
          <div className="flex flex-col justify-start">
            <span className="text-white font-bold text-left">{name}</span>
            <span className="text-gray-300 text-left">{artist}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TrackCard;
