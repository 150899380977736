import {configureStore} from '@reduxjs/toolkit';
import userSlice from './slices/UserSlice';
import playerSlice from './slices/PlayerSlice';
import toastSlice from './slices/ToastSlice';
import playListSlice from './slices/PlaylistSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    player: playerSlice,
    toast: toastSlice,
    playlists: playListSlice,
  },
});

export default store;
