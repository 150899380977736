import axios from 'axios';
import getRefreshToken from '../utils/SpotifyRefresh';

const axiosInstance = axios.create({
  baseURL: 'https://api.spotify.com/v1',
});

axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const tokenRefreshed = await getRefreshToken();
        if (tokenRefreshed) {
          const newAccessToken = localStorage.getItem('access_token');
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        }
      }
      return Promise.reject(error);
    },
);

export default axiosInstance;
