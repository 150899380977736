import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setPlaylistName, setDescription, createPlaylist} from '../slices/PlaylistSlice';

const CreatePlaylistModal = ({onClose}) => {
  const dispatch = useDispatch();
  const playlistName = useSelector((state) => state.playlists.playlistName);
  const description = useSelector((state) => state.playlists.description);
  const userId = useSelector((state) => state.user.userID);

  const handleNameChange = (e) => {
    dispatch(setPlaylistName(e.target.value));
  };

  const handleDescriptionChange = (e) => {
    dispatch(setDescription(e.target.value));
  };

  const handleSubmit = () => {
    dispatch(createPlaylist({userId, name: playlistName, description}));
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <div className="mt-2">
            <input
              type="text"
              placeholder="Playlist Name"
              value={playlistName}
              onChange={handleNameChange}
              className="px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent"
            />
            <textarea
              placeholder="Description"
              value={description}
              onChange={handleDescriptionChange}
              className="mt-2 px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent"
            />
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-indigo-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Create
            </button>
            <button
              onClick={onClose}
              className="mt-3 px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreatePlaylistModal;
