import axiosInstance from '../interceptor/interceptor';

const SPOTIFY_API_ENDPOINT = '/me';

async function fetchSpotifyUserInfo() {
  try {
    const response = await axiosInstance.get(SPOTIFY_API_ENDPOINT);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching Spotify user info:', error);
    return null;
  }
}

export default fetchSpotifyUserInfo;
