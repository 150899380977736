import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {getTrackInfo, getTracksByArtist} from '../utils/SpotifyAlbum';
import {setPlayingTrack} from '../slices/PlayerSlice';
import {useDispatch} from 'react-redux';
import TrackCard from './TrackCard';
import Addtrack from './AddTrack';


function TrackDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const trackId = params.trackId;
  const [track, setTrack] = useState(null);
  const [artistTracks, setArtistTracks] = useState([]);
  const [addList, setAddList] = useState(false);

  const ShowAddList = () => setAddList(true);
  const hideAddList = () => setAddList(false);

  const handlePlay= () => {
    dispatch(setPlayingTrack(track));
  };

  const handleUP = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchAlbumInfo = async () => {
      const trackData = await getTrackInfo(trackId);
      setTrack(trackData);
      console.log(trackData);
      if (trackData?.artists?.[0]?.id) {
        const artistTracksData = await getTracksByArtist(trackData.artists[0].id);
        setArtistTracks(artistTracksData);
      }
    };

    if (trackId) {
      fetchAlbumInfo();
    }
  }, [trackId]);

  if (!track) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-start shadow-xl overflow-hidden p-8 lg:p-20 space-y-4 bg-white rounded-lg">
      {addList && <Addtrack onClose={hideAddList} trackUri={track.uri}/>}
      <h1 className="text-2xl lg:text-4xl font-bold text-gray-900">{track.name}</h1>
      <p className="text-lg text-gray-700">Artist: {track?.artists?.[0]?.name ?? 'Unknown Artist'}</p>
      <p className="text-lg text-gray-600">Release Date: {track.album.release_date}</p>
      <img
        alt={track.name}
        className="mt-4 w-64 h-64 object-cover rounded-lg shadow-lg"
        src={track.album.images[0].url}
      />
      <div className='flex gap-4 mt-4'>
        <svg onClick={handlePlay} className="h-10 w-10 text-green-400 hover:text-green-600 cursor-pointer" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="12" cy="12" r="10" />
          <polygon points="10 8 16 12 10 16 10 8" />
        </svg>
        <svg onClick={ShowAddList} className="h-10 w-10 text-gray-600 hover:text-gray-800 cursor-pointer" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
          <line x1="12" y1="8" x2="12" y2="16" />
          <line x1="8" y1="12" x2="16" y2="12" />
        </svg>
      </div>
      <div className="mt-6 w-full">
        <h2 className="text-xl lg:text-2xl font-bold text-gray-900">Other tracks by {track?.artists?.[0]?.name}</h2>
        <div className="mt-4 space-y-4" onClick={handleUP}>
          {artistTracks.map((track) => (
            <TrackCard key={track.id} trackID={track.id} name={track.name} artist={track.artists[0].name} img={track.album.images[0].url} />
          ))}
        </div>
      </div>
    </div>

  );
}

export default TrackDetails;
