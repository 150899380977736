import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {createPlaylist as createPlaylistAPI} from '../utils/SpotifyPlayList';

export const createPlaylist = createAsyncThunk(
    'playlists/createPlaylist',
    async ({userId, name, description}, thunkAPI) => {
      const response = await createPlaylistAPI(userId, {name, description});
      return response;
    },
);

const playlistsSlice = createSlice({
  name: 'playlists',
  initialState: {
    playlistName: '',
    description: '',
    loading: false,
    error: null,
  },
  reducers: {
    setPlaylistName: (state, action) => {
      state.playlistName = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(createPlaylist.pending, (state) => {
          state.loading = true;
        })
        .addCase(createPlaylist.fulfilled, (state, action) => {
          state.loading = false;
        })
        .addCase(createPlaylist.rejected, (state, action) => {
          state.error = action.error.message;
          state.loading = false;
        });
  },

});

export const selectPlaylists = (state) => state.playlists.items;
export const {setPlaylistName, setDescription} = playlistsSlice.actions;
export default playlistsSlice.reducer;
