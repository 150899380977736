import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getPlaylistTracks} from '../utils/SpotifyPlayList';
import TrackCard from './TrackCard';

function PlaylistsTrack() {
  const {playlistsId} = useParams();
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    const fetchPlaylistTracks = async () => {
      try {
        const playListTrack = await getPlaylistTracks(playlistsId);
        console.log(playListTrack.items);
        setTracks(playListTrack.items);
      } catch (error) {
        console.error('Error fetching playlist tracks: ', error);
      }
    };

    fetchPlaylistTracks();
  }, [playlistsId]);

  return (
    <div>
      {tracks.map((track) => (
        <TrackCard key={track.track.id} trackID={track.track.id} name={track.track.name} img={track.track.album.images[0].url} />
      ))}
    </div>
  );
};

export default PlaylistsTrack;
