/* eslint-disable prefer-promise-reject-errors */
import axiosInstance from '../interceptor/interceptor';

const SPOTIFY_API_ENDPOINT = '/me/playlists';

async function fetchSpotifyPlaylists() {
  try {
    const response = await axiosInstance.get(SPOTIFY_API_ENDPOINT);
    const data = response.data;
    return data.items ?? [];
  } catch (err) {
    console.log(err);
  }
}

export const createPlaylist = async (userId, {name, description}) => {
  const endpoint = `/users/${userId}/playlists`;
  const bodyData = {
    name,
    description,
    public: true,
  };

  try {
    const response = await axiosInstance.post(endpoint, bodyData);
    return response.data;
  } catch (error) {
    console.error(`Error creating playlist: ${error}`);
    return Promise.reject(error);
  }
};

export async function getPlaylistTracks(PlayListId) {
  try {
    const response = await axiosInstance.get(`/playlists/${PlayListId}/tracks`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export async function addTracktoPlaylist(playlistId, trackUrl, position) {
  try {
    const bodyData = {
      uris: [trackUrl],
      position,
    };
    const response = await axiosInstance.post(`/playlists/${playlistId}/tracks`, bodyData);
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}


export default fetchSpotifyPlaylists;
