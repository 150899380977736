import React, {useState} from 'react';
import AlbumCard from '../component/AlbumCard';
import '../CSS/HomePage.css';
import {useDispatch, useSelector} from 'react-redux';
import {fetchRecommendations} from '../slices/PlayerSlice';
import RecToast from '../component/RecToast';
import TrackCard from '../component/TrackCard';

function HomePage() {
  const dispatch = useDispatch();
  const RecommendationsTrack = useSelector((state) => state.player.RecommendationsTrack);
  const RecommendationsAlbum = useSelector((state) => state.player.RecommendationsAlbum);
  const [showRecommendations, setShowRecommendations] = useState(false);

  const handleRefresh = () => {
    dispatch(fetchRecommendations());
    setShowRecommendations(true);
  };

  return (
    <div className="home-container">
      {showRecommendations ? (
        <>
          <button className="bg-transparent hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 p-2 rounded-full" onClick={handleRefresh}>
            <img src="https://react-app-spotify.s3.amazonaws.com/refresh.png" alt="Refresh" className="h-6 w-6" />
          </button>
          <div className="title">Album Recommendations</div>
          <div className="cards-container">
            {RecommendationsAlbum.map((album, index) => (
              <AlbumCard
                key={`album-${index}`}
                name={album.name}
                artist={album.artist}
                img={album.imageUrl}
                albumID={album.id}
              />
            ))}
          </div>
        </>
      ): (
        <div>
          <div className=" sm:px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Tailored Tunes
                <br />
              Discover Songs and Albums Today
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              Explore, enjoy, and let your auditory adventure unfold in the most personalized way possible.
              Your next musical love affair starts here.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <button
                  onClick={handleRefresh}
                  href="#"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                Get started
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="pb-16">
        {showRecommendations && (
          <>
            <div className="title">Track Recommendations</div>
            <div>
              {RecommendationsTrack.map((track, index) => (
                <TrackCard
                  key={`album-${index}`}
                  name={track.name}
                  artist={track.artist}
                  img={track.imageUrl}
                  trackID={track.id}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <RecToast />
    </div>
  );
}

export default HomePage;
