/* eslint-disable react/no-unescaped-entities */
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {getAlbumInfo} from '../utils/SpotifyAlbum';
import {setPlayingTrack} from '../slices/PlayerSlice';
import {useDispatch} from 'react-redux';
import TrackCard from './TrackCard';

function AlbumDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const albumId = params.albumId;
  const [track, setTrack] = useState(null);
  const [albumTracks, setAlbumTracks] = useState([]);
  const [albumImage, setAlbumImage] = useState('');

  const handlePlay= () => {
    dispatch(setPlayingTrack(track));
  };

  const handleUP = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchAlbumInfo = async () => {
      const albumData = await getAlbumInfo(albumId);
      setAlbumTracks(albumData.tracks.items);
      setAlbumImage(albumData.images[0].url);
      setTrack(albumData);
    };

    if (albumId) {
      fetchAlbumInfo();
    }
  }, [albumId]);

  if (!track) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-start shadow-xl overflow-hidden p-8 lg:p-20 space-y-4 bg-white rounded-lg">
      <h1 className="text-2xl lg:text-4xl font-bold text-gray-900">{track.name}</h1>
      <p className="text-lg text-gray-700">Artist: {track?.artists?.[0]?.name ?? 'Unknown Artist'}</p>
      <p className="text-lg text-gray-600">Release Date: {track.release_date}</p>
      <img
        alt={track.name}
        className="mt-4 w-64 h-64 object-cover rounded-lg shadow-lg"
        src={track.images[0].url}
      />
      <div className='flex gap-4 mt-4'>
        <svg onClick={handlePlay} className="h-10 w-10 text-green-400 hover:text-green-600 cursor-pointer" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="12" cy="12" r="10" />
          <polygon points="10 8 16 12 10 16 10 8" />
        </svg>
      </div>
      <div className="mt-6 w-full">
        <h2 className="text-xl lg:text-2xl font-bold text-gray-900">Tracks on the album "{track?.name}"</h2>
        <div className="mt-4 space-y-4" onClick={handleUP}>
          {albumTracks.map((track) => (
            <TrackCard key={track.id} trackID={track.id} name={track.name} artist={track.artists[0].name} img={albumImage}/>
          ))}
        </div>
      </div>
    </div>

  );
}

export default AlbumDetails;
