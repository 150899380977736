import axiosInstance from '../interceptor/interceptor';

export const getTopTracks = () => {
  const SPOTIFY_API_ENDPOINT = '/me/top/tracks';
  return axiosInstance.get(SPOTIFY_API_ENDPOINT)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
};

export const getRecommendations = (seedTracks) => {
  const seedTracksParam = seedTracks.join(',');
  const SPOTIFY_API_ENDPOINT = `/recommendations?seed_tracks=${seedTracksParam}`;
  return axiosInstance.get(SPOTIFY_API_ENDPOINT)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error;
      });
};


export const Recommendations = () => {
  return getTopTracks()
      .then((topTracksResponse) => {
        const seedTracks = topTracksResponse.items.slice(0, 5).map((track) => track.id);
        return getRecommendations(seedTracks);
      })
      .then((recommendationsData) => {
        const tracks = recommendationsData.tracks.map((track, index) => ({
          index: index + 1,
          name: track.name,
          artist: track.artists.map((artist) => artist.name).join(', '),
          id: track.id,
          imageUrl: track.album.images[0].url,
        }));

        const albumsMap = recommendationsData.tracks.reduce((acc, track) => {
          const albumId = track.album.id;
          if (!acc.has(albumId)) {
            acc.set(albumId, {
              id: track.album.id,
              name: track.album.name,
              imageUrl: track.album.images[0].url,
              artist: track.album.artists.map((artist) => artist.name).join(', '),
            });
          }
          return acc;
        }, new Map());

        const albums = Array.from(albumsMap.values());

        return {tracks, albums};
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
};
