import React, {useEffect, useState} from 'react';
import fetchSpotifyPlaylists from '../utils/SpotifyPlayList';
import CreatePlaylistModal from './CreatePlaylistModal';
import '../CSS/PlayLists.css';
import {Link} from 'react-router-dom';

function PlaylistCard({playlist}) {
  const hasImage = playlist.images && Array.isArray(playlist.images) && playlist.images.length > 0;

  return (
    <div className="playlist-card">
      {hasImage && (
        <img src={playlist.images[0].url} alt={playlist.name} className="playlist-image" />
      )}
      <div className="playlist-name">{playlist.name}</div>
    </div>
  );
}


function Playlists({token}) {
  const [playlists, setPlaylists] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchPlaylists = async () => {
      const userPlaylists = await fetchSpotifyPlaylists(token);
      setPlaylists(userPlaylists);
    };

    fetchPlaylists();
  }, [token]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="playlists-container">
      {isModalOpen && <CreatePlaylistModal onClose={handleCloseModal} />}
      {playlists.length > 0 ? (
        <div className="playlists-grid">
          {playlists.map((playlist) => (
            <Link key={playlist.id} to={`/playliststrack/${playlist.id}`}>
              <PlaylistCard key={playlist.id} playlist={playlist} />
            </Link>
          ))}
          <button className="add-button" onClick={handleOpenModal}>
            <img src="https://react-app-spotify.s3.amazonaws.com/add.png" alt="Add" className="icon" />
          </button>
        </div>
      ) : (
        <p>No playlists found.</p>
      )}
    </div>
  );
}

export default Playlists;
