import React, {useState, useEffect} from 'react';
import Player from 'react-spotify-web-playback';
import '../CSS/SpotifyPlayer.css';
import {useSelector} from 'react-redux';


function SpotifyPlayer() {
  const [play, setPlay] = useState(false);
  const playingTrack = useSelector((state) => state.player.playingTrack);
  const accessToken = localStorage.getItem('access_token');

  useEffect(() => {
    setPlay(true);
  }, [playingTrack.uri]);

  const handlePlayerCallback = async (state) => {
    if (!state.isPlaying) {
      setPlay(false);
    }
  };

  return (
    <div className="player-container">
      <Player
        token={accessToken}
        callback={handlePlayerCallback}
        play={play}
        uris={playingTrack.uri ? [playingTrack.uri] : []}
      />
    </div>
  );
}

export default SpotifyPlayer;
