import React from 'react';
import Dashboard from '../component/Dashboard';

function SearchPage() {
  return (
    <div className="text-center p-8 w-full">
      <h2 className="text-2xl font-bold">Find Your Song</h2>
      <Dashboard/>
    </div>
  );
}

export default SearchPage;
