import React from 'react';
import {Link} from 'react-router-dom';
import '../CSS/TrackSearchResult.css';

export default function TrackSearchResult({track}) {
  return (
    <Link to={`/track/${track.id}`} className="no-underline">
      <div className="track-container">
        <img src={track.albumUrl} alt={track.title} className="track-image" />
        <div className="track-info">
          <div className="track-title">{track.title}</div>
          <div className="track-artist">{track.artist}</div>
        </div>
      </div>
    </Link>
  );
}
